'use client';

import { Chapters } from '@/app/detail/components/chapter-data';
import { getCreateSingleInstanceModalContent } from '@/components/form/default-create-form/get-create-single-instance-modal-content';
import type { Chapter } from '@/components/form/types';
import {
  CONTRACT_MODAL_SOURCE_KEY,
  DETAIL_MODAL_SOURCE_KEY,
} from '@/components/ui/detailModalLink';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import WindowKeeperItem from '@/components/window-keeper/window-keeper-items';
import { useGetTenant } from '@/config/accounts/users/customer/tenant/client';
import { useModal } from '@/hooks/use-modal';
import { useConfig } from '@/hooks/useConfig';
import { useWindowKeeper } from '@/hooks/useWindowKeeper';
import { cn } from '@/lib/cn';
import type { Preview } from '@/providers/windowkeeperProvider';
import { useGetInstance } from '@/requests/hooks';
import { useBulkTabStore } from '@/store';
import {
  ApartmentConfig,
  BrfCompanyConfig,
  getConfig,
  InvoiceConfig,
  type Apartment,
  type BaseInstance,
  type BrfCompany,
  type IBaseInstanceConfig,
  type Invoice,
  type ModelName,
} from '@pigello/pigello-matrix';
import { usePathname, useRouter } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import store from 'store';
import useResizeObserver from 'use-resize-observer';
import { Icons } from '@/components/icons';

type Instances = BaseInstance & Invoice & Apartment & BrfCompany;

const NotVisibleInPathnames = ['/detail', '/login', '/bulk', '/contracts'];

const ItemWithTooltip = ({
  preview,
  itemWidth,
  onPreviewClick,
  removePreview,
}: {
  preview: Preview;
  itemWidth: number;
  onPreviewClick: (
    preview: Preview,
    config?: IBaseInstanceConfig<BaseInstance>
  ) => void;
  removePreview: (previewIdentifier: string) => void;
}) => {
  const modelName = preview.display.split(':')[0] as ModelName;
  const { config } = useConfig(modelName);

  const { data, isLoading } = useGetInstance<Instances>({
    modelName: modelName,
    id: preview?.instanceId,
    enabled: !!preview?.instanceId,
    nested: ['company'],
  });

  const tenantId = data?.invoiceRecipients?.at(0)?.tenant?.id;
  const { data: tenant, isLoading: isTenantLoading } = useGetTenant({
    id: tenantId,
    enabled:
      !!tenantId && !isLoading && config?.modelName === InvoiceConfig.modelName,
  });

  const title = useMemo(() => {
    const displayName = preview.display.split(':')[1].trim();
    const isDisplayNameFalsy = !displayName || displayName === 'null';
    const missingId = 'ID saknas';
    const fallbackName = isDisplayNameFalsy ? missingId : displayName;

    if (modelName === BrfCompanyConfig.modelName) {
      const company = data?.company;

      return (
        company?.name || company?.orgNo || company?.customId || fallbackName
      );
    }

    if (modelName === InvoiceConfig.modelName) {
      return data?.invoiceNumber || tenant?.fullName || fallbackName;
    }

    if (!isDisplayNameFalsy) {
      return displayName;
    }

    if (modelName === ApartmentConfig.modelName) {
      return isDisplayNameFalsy
        ? data?.apartmentId || data?.customId || missingId
        : displayName;
    }

    return isDisplayNameFalsy
      ? data?.customId || data?.popularName || missingId
      : displayName;
  }, [data, modelName, tenant, preview]);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <WindowKeeperItem
          itemWidth={itemWidth}
          onPreviewClick={onPreviewClick}
          preview={preview}
          removePreview={removePreview}
          title={
            isLoading || isTenantLoading ? (
              <Icons.loader className='mt-1 size-3 animate-spin' />
            ) : (
              title
            )
          }
        />
      </TooltipTrigger>
      <TooltipContent>{title}</TooltipContent>
    </Tooltip>
  );
};

export const WindowKeeperTabs = ({ isDetail }: { isDetail?: boolean }) => {
  const [itemWidth, setItemWidth] = useState(200);
  const [displayOnDetailPage, setDisplayOnDetailPage] = useState(false);
  const pathname = usePathname();
  const { openModal } = useModal();

  const isVisible = useMemo(() => {
    return !NotVisibleInPathnames.some((start) => pathname.startsWith(start));
  }, [pathname]);

  const { previews, getStored, removePreview } = useWindowKeeper();

  const calculateItemWidth = useCallback(() => {
    const totalWidth = window.innerWidth;
    if (!totalWidth) return 0;
    const itemsCount = previews.length;

    const widthPerItem = (totalWidth - (itemsCount * 8 + 16)) / itemsCount;

    if (widthPerItem > 200) {
      setItemWidth(200);
    } else {
      setItemWidth(widthPerItem);
    }
  }, [previews.length]);

  const { ref: horizontalRef } = useResizeObserver<HTMLDivElement>({
    onResize: calculateItemWidth,
  });

  const router = useRouter();

  useEffect(() => {
    if (previews.length === 0) {
      document.documentElement.style.setProperty(
        '--window-keeper-height',
        '100vh'
      );
    } else {
      document.documentElement.style.setProperty(
        '--window-keeper-height',
        'calc(100vh - 50px)'
      );
    }
  }, [previews.length]);

  const onPreviewClick = async (
    preview: Preview,
    config?: IBaseInstanceConfig<BaseInstance>
  ) => {
    const stored = getStored(preview.identifier);

    if (!stored) return;

    if (stored.type === 'nonsavedinstance') {
      if (stored.formPath) {
        store.set(CONTRACT_MODAL_SOURCE_KEY, pathname);
        router.push(stored.formPath);
      } else if (config) {
        openModal({
          contentData: {
            config,
            chapters: Chapters[
              `${config.modelName}${
                stored.chapterSuffix ? stored.chapterSuffix : ''
              }` as keyof typeof Chapters
            ] as Chapter<BaseInstance>,
            persistedIdentifier: stored.identifier,
            cleanInstance: () => ({}),
            title: 'Skapa',
          },
          getModalContent: getCreateSingleInstanceModalContent,
        });
      }
      return;
    }

    if (!pathname.includes('/detail')) {
      store.set(DETAIL_MODAL_SOURCE_KEY, pathname);
    }

    if (stored.type === 'instance') {
      router.push(stored.detailPath);
    } else if (stored.type === 'bulks') {
      //open bulk tab

      //we cannot minimize/reopen a "mode: download" tab.
      if (stored.mode === 'download') return;

      const config = await getConfig(stored.modelName);

      useBulkTabStore.getState().openNewBulk({
        config,
        //doesn't matter, we're opening existing bulk
        instances: [],
        mode: stored.mode,
        winId: stored.bulkId,
      });

      removePreview(stored.identifier);
    }
  };

  useEffect(() => {
    calculateItemWidth();
  }, [previews, isVisible, calculateItemWidth]);

  if (
    (!isDetail && (!isVisible || previews.length === 0)) ||
    (isDetail && previews.length === 0)
  )
    return null;

  return (
    <>
      {isDetail && !displayOnDetailPage && (
        <div
          className='absolute inset-x-0 bottom-0 z-[999] h-[50px] bg-transparent'
          onMouseEnter={() => setDisplayOnDetailPage(true)}
        ></div>
      )}
      <div
        ref={horizontalRef}
        onMouseLeave={() => setDisplayOnDetailPage(false)}
        className={cn(
          'flex h-[50px] w-screen items-center gap-2 border-t border-border bg-muted px-4',
          {
            'absolute bottom-0  z-[1000] transition-all': isDetail,
            'translate-y-[100%]': isDetail && !displayOnDetailPage,
          }
        )}
      >
        {previews.map((preview) => (
          <ItemWithTooltip
            key={preview.identifier}
            preview={preview}
            itemWidth={itemWidth}
            onPreviewClick={onPreviewClick}
            removePreview={removePreview}
          />
        ))}
      </div>
    </>
  );
};
